<template>
    <div id="not-found">
        <div class="wrapper">
            <div class="container">
                <h1>That's odd...</h1>
                <p>
                    <i>I sense something; a presence I've not felt since...</i>
                </p>
                <p>It seems that you've found something that shouldn't be there. Sadly we've no content to show you...</p>
                <p>
                    <br>
                </p>
                <h3>Not the end of the world!</h3>
                <p>There is still stuff to be explored. Click
                    <router-link to="/">here</router-link>
                    to begin your journey!
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Unknown"
}
</script>
<style scoped>
</style>
